.a11y #drawer-btn {
    display: none;
}

.a11y .hero {
    background: #f5f5f5
}

.a11y .hero__background {
    display: none
}