body {
    /* prevengono la scomparsa della scrollbar causata da Popover */
    overflow: auto !important;
    padding: 0 !important;
}

.body--hidden-scrollbar {
    overflow: hidden !important;
}

.MuiToolbar-root {
    padding-left: 16px !important
}

.MuiDialog-paperFullScreen {
    width: 95% !important;
    height: 95% !important;
}

.a11y input:focus, .a11y textarea:focus, .a11y a:focus, .a11y button:focus {
    background-color: #91A7C7 !important;
    outline: #00295a auto 1px !important;
    outline-offset: -1px !important;
}

.skip-link {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: auto;
    clip: rect(0, 0, 0, 0);
    padding: 4px 0;
    text-align: center;
    background-color: rgba(44, 44, 44, 0.5);
    color: #fff;
}

.skip-link:active,
.skip-link:focus {
    clip: auto;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.element--fullscreen {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background: #FFFFFF !important;
    padding: 16px !important;
    z-index: 1299 !important;
    border-radius: unset !important;
    box-shadow: unset !important;
}